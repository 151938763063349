import {FC, useCallback, useEffect, useMemo, useState} from "react";

import NoResults from "common/components/NoResults";
import LoadingSpinner from "common/components/spinner";
import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {AccordionTable, OrderBy} from "@bolteu/kalep-table-react";

import {useTableColumns} from "../hooks/useTableColumns";
import AssigmentVehicle = FleetShiftManagementService.AssigmentVehicle;
import AssigmentShift = FleetShiftManagementService.AssigmentShift;
import AssignmentDriver = FleetShiftManagementService.AssignmentDriver;
import DriverShiftAssignmentUpdate = FleetShiftManagementService.DriverShiftAssignmentUpdate;

export interface ShiftAssignmentsTableProps {
    tableData: ShiftTableData[];
    vehicles: AssigmentVehicle[] | undefined;
    shifts: AssigmentShift[] | undefined;
    updatedAssignments: Map<number, DriverShiftAssignmentUpdate>;
    onAssignmentChange: (id: number, updatedAssignment: Partial<AssignmentDriver>) => void;
    isMobileView: boolean;
    isLoading: boolean;
}

export interface ShiftTableData {
    id: number;
    driver_name: string;
    vehicle_id: number | null;
    shift_id: number | null;
}

const ShiftAssignmentsTable: FC<ShiftAssignmentsTableProps> = ({
    vehicles,
    shifts,
    tableData,
    updatedAssignments,
    onAssignmentChange,
    isMobileView,
    isLoading,
}) => {
    const {tableColumns, accordionTableColumns} = useTableColumns(
        tableData,
        vehicles,
        shifts,
        updatedAssignments,
        onAssignmentChange,
    );
    const [sortedRows, setSortedRows] = useState<ShiftTableData[]>([]);
    const [orderBy, setOrderBy] = useState<OrderBy<ShiftTableData>>();

    const updatedTableData = useMemo(() => {
        return tableData.map((driver) => {
            const updatedAssignment = updatedAssignments.get(driver.id);
            if (updatedAssignment) {
                return {
                    ...driver,
                    vehicle_id: updatedAssignment?.vehicle_id,
                    shift_id: updatedAssignment?.shift_id,
                };
            }

            return driver;
        });
    }, [tableData, updatedAssignments]);

    const getItemHeader = useCallback(
        (item: ShiftTableData) => ({
            primary: item.driver_name,
        }),
        [],
    );

    const handleOrderByChange = useCallback(
        (newOrder?: OrderBy<ShiftTableData>) => {
            setOrderBy(newOrder);
            if (!newOrder) {
                setSortedRows(updatedTableData);
                return;
            }

            const directionMultiplier = newOrder.direction === "ASC" ? 1 : -1;
            setSortedRows(
                [...updatedTableData].sort((a, b) => {
                    // Only driver name is a sortable column
                    return directionMultiplier * a.driver_name.localeCompare(b.driver_name);
                }),
            );
        },
        [updatedTableData],
    );

    const renderEmptyPlaceholder = useCallback(() => {
        return isLoading ? <LoadingSpinner show parentClassName="mt-2" /> : <NoResults />;
    }, [isLoading]);

    useEffect(() => {
        if (updatedTableData) {
            setSortedRows(updatedTableData);
        }
    }, [updatedTableData]);

    if (isMobileView) {
        if (!isLoading && !updatedTableData.length) {
            return <NoResults />;
        }

        return (
            <AccordionTable
                getItemHeader={getItemHeader}
                columns={accordionTableColumns}
                items={updatedTableData}
                aria-label="Shift assignments table"
                isLoading={isLoading}
                paddingStart={0}
                paddingEnd={0}
            />
        );
    }
    return (
        <FixedHeaderTable
            columns={tableColumns}
            items={sortedRows}
            onOrderByChange={handleOrderByChange}
            orderBy={orderBy}
            aria-label="Shift assignments table"
            renderEmptyPlaceholder={renderEmptyPlaceholder}
            isLoading={isLoading}
        />
    );
};

export default ShiftAssignmentsTable;
